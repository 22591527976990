"use client";
import { Inter } from "next/font/google";
import "./globals.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PropTypes from "prop-types";
import { Toaster } from "sonner";
import Script from "next/script";

// export const metadata = {
//   title: "Scholar Earth - Home",
//   description: "",
// };

export default function RootLayout({ children }) {
  return (
    <html lang="en">
      <head>
        {/* <title>{pageTitle}</title> */}
        <title>Scholar Earth - Your trusted study abroad advisor</title>
        <meta charSet="utf-8" />
        <meta
          name="description"
          content="Scholar Earth: Get dream university admissions with personalized counseling, IELTS prep, financial aid, and visa assurance."
        />
        <meta
          name="keywords"
          content="Study abroad, study overseas, overseas education, higher education in abroad, study abroad programs, study abroad colleges, study abroad courses, international studies, student visa, student loan, search courses"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
        />
        <Script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-MXYSTLM4Q5"
        ></Script>

        <Script strategy="lazyOnload" id="ga4-script">
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-MXYSTLM4Q5');
        `}
        </Script>

        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=G-MXYSTLM4Q5"></script> <script> window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-MXYSTLM4Q5'); </script> */}
      </head>

      <body>{children}</body>
    </html>
  );
}

RootLayout.propTypes = {
  children: PropTypes.node.isRequired,
  // pageTitle: PropTypes.string,
};
